import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const About = (props) => {
  
  return (
    <Layout>
      <SEO title="About" />
      
        <div className="container">
          <br></br>

          <br></br>
          <div className="about-title">
            <h1>About</h1>
          </div>

          <div className="about-paragraph">
          <p>
            Dr. Ralph Mayer is a 26-year veteran of the war on his cuticles. The demands of a busy surgeon on the cuticles is second to none. Dr. Ralph created cuticle miracle out of a personal need to have presentable hands that withstand the daily grind of his profession.
          </p>

          <p>
            Though he created it for himself, it is the perfect solution for any busy professional who constantly washes and uses their hands who also wants soft beautiful cuticles but doesn’t want to constantly reapply. The unique formula of cuticle miracle allows it to stay in the cuticular base for long periods of time through most washings and hand sanitizing. 
          </p>

          <p>
            The Unique applicator allows you to easily apply Cuticle Miracle deep into the cuticle without any mess. The tube can be stored conveniently in a pocket or purse, glove box, change holder, or home office.
          </p>
          </div>
          <div className="iframe-container"> 
          <iframe src="https://www.youtube.com/embed/G2zM8OTdEGw" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
        <br></br>

    </Layout>
  );
};

export const query = graphql`
  query AboutQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/about/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`;

export default About;
